<template>
	<div
		class="contain"
		v-infinite-scroll="load"
		:infinite-scroll-disabled="finish">
		<searchBar :searchInput="false"></searchBar>

		<el-button-group class="mb_15">
            <el-button size="large" @click="backTask">管理任务</el-button>
            <el-button type="primary" size="large" >
                打款管理
            </el-button>
        </el-button-group>

		<div class="title bgf flex">
			<div class="flex_col body">
				<div class="flex_c mb_10">
					<p class="fs_16 bold color_333 mr_15">{{ reward_name || '' }}</p>
					<p class="fs_12 color_999">任务编号 {{ detail?.reward_no  }}</p>
				</div>
				<div class="flex">
					<div class="use_tip color_333">
						<span class="line color_red">{{ detail?.settle_info }}</span>
						<span class="line">{{ detail?.price_type }}</span>
						<span class="line">{{ detail?.settle_type }}</span>
						<span class="line">{{ detail?.publish_user_name }}发布</span>
					</div>
				</div>
			</div>
		</div>

		<div class="subtitle flex mb_15">
			<div class="flex_c">
				<div
					class="button color_999 fs_14 flex_cen btn mr_10"
					:class="{ active: tab === null }"
					@click.stop="changeTab(null)"
				>
					<span class="center">全部</span>
				</div>
				<div
					class="button color_999 fs_14 flex_cen btn mr_10"
					:class="{ active: tab === 0 }"
					@click.stop="changeTab(0)"
				>
					<span class="center">待打款</span>
				</div>
				<!-- <div
					class="button color_999 fs_14 flex_cen btn mr_10"
					:class="{ active: tab === 1 }"
					@click.stop="changeTab(1)"
				>
					<span class="center">待打款</span>
				</div> -->
				<div
					class="button color_999 fs_14 flex_cen btn mr_10"
					:class="{ active: tab == 2 }"
					@click.stop="changeTab(2)"
				>
					<span class="center">已打款</span>
				</div>
				<!-- <div
					class="button color_999 fs_14 flex_cen btn mr_10"
					:class="{ active: tab === -1 }"
					@click.stop="changeTab(-1)"
				>
					<span class="center">已拒绝</span>
				</div> -->
			</div>
		</div>

		<div class="title subtitle bgf flex">
			<div class="flex_c flex_1">
				<el-date-picker
					v-model="ruleForm.fromTo"
					:editable="false"
					type="daterange"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="timechange"
					format="YYYY/MM/DD"
					value-format="YYYY-MM-DD"
				/>
			</div>
			<div class="flex_c">
				<div>
					<el-input
						placeholder="输入搜索关键词"
						class="fs_14"
						ref="search"
						v-model.trim="ruleForm.keywords"
						@keyup="searchKeywords"
					>
						<template #suffix>
							<i class="el-icon-search btn" @click="handleClick" ></i>
						</template>
					</el-input>
				</div>
				<el-button class="ml_10" type="primary" size="large" @click="handleClick" :loading="loading">搜 索</el-button>
			</div>
		</div>

		<div class="mt_15"></div>

		<div class="bgf" v-if="list.length != 0">
			<div class="list">
				<div
					class="item flex bottom_link"
					v-for="(item, index) in list"
					:key="index"
				>
					<div class="left flex">
						<el-avatar
							shape="circle"
							:size="50"
							:fit="'contain'"
							:src="item.user && item.user.avatar"
							class="mr_20"
						>
							<img :src="$store.state.baseAvatar" />
						</el-avatar>
						<div class="flex_col">
							<div class=" color_333  flex_c">
								<span class="mr_10 fs_20 bold">{{ item.user && item.user.realname }}</span>
                                <!-- <span class="tag fs_12 center" :class="{in: item.has_invoice!=0}">{{ item.has_invoice==0?'未申请开票':'已申请开票' }}</span> -->
								<span class="tag fs_12 center" :class="{in: item.status == 2}">{{ item.status == 2 ? '已打款': '未打款' }}</span>
							</div>
							<p class="fs_14 mt_10">
								<span class="sub_color label">接单流水号：</span>
								<span class="color_333">{{ item.apply.apply_no }}</span>
							</p>
						</div>
					</div>
					<div class="right flex userinfo_tips">
						<div class="flex_col fs_12 ritem line center">
							<p class="sub_color mb_8">记录时间</p>
							<p class="">{{ item.update_time }}</p>
						</div>
						<div class="flex_col fs_12 ritem line center">
							<p class="sub_color mb_8">记录人</p>
							<p class="">{{ item.op_user }}</p>
						</div>
                        <div class="flex_col fs_12 ritem center">
							<p class="mb_8 fs_12">￥<span class="fs_20 bold">{{ item.total_amount }}</span></p>
							<p class="btn flex_cen" v-if="!item.upload_image" @click="upload(item)">{{ status == 1 ? '上传' : '未上传' }}支付凭证</p>
							<p class="btn flex_cen in" v-else @click="payImg=item.upload_image;dialogVisible=true;">查看支付凭证</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<empty :finish="finish" :length="list.length" v-else></empty>
		<pay-msg :id="payId" :type="1" ref="paymsg" @init="handleClick"></pay-msg>
		<el-dialog v-model="dialogVisible" :lock-scroll="true" width="492px" top="20vh">
            <img :src="payImg" alt="">
        </el-dialog>
	</div>
</template>
<script setup>
	import payMsg from "../employment/dialog/pay-msg"
	import { useRoute, useRouter } from "vue-router"
	// import { Search } from "@/element-plus/icons-vue"
	import { ref, getCurrentInstance } from "vue"
	import { s_getItem } from "@/common/cache"

	const { proxy } = getCurrentInstance()
	// console.log(proxy, proxy.$axios)
	const finish = ref(false)
	const route = useRoute()
	const router = useRouter()
	const dialogVisible = ref(false)

	const reward_name = ref(route.params.name)
	const rewardId = ref(route.params.id)
	const ruleForm = ref({
		fromTo: [],
		startTime: "",
		endTime: "",
		keywords: ""
	})
	const tab = ref(null)

	const paymsg = ref(null)

	const payId = ref('')

	let page = 1

	const list = ref([])


	const detail = ref(s_getItem("_taskDetail"))


	const search = ref(null)
	const loading = ref(false)


	const load = () => {
		page++
		init()
	}

	const upload = (item) => {
		// if(item.status == 2)return
		payId.value = item.id
		paymsg.value.form.price = item.total_amount
		paymsg.value.dialogVisible = true
	}


	const init = () => {
		loading.value = true
		console.log(new Date(ruleForm.value.startTime))
		proxy.$axios({
				url: '/ent/v4/spe/batch',
				hideLoading: true,
				data: {
					page,
					limit: 10,
					status: tab.value,
					start_time: ruleForm.value.startTime,
					end_time: ruleForm.value.endTime,
					reward_id: rewardId.value,
					keywords: ruleForm.value.keywords
				}
			}).then(res => {
				if (res.code == 200 && res.data?.list) {
					list.value.push(...res.data.list)
					if (res.data.list.length < 10) {
						finish.value = true
					}
				}
				loading.value = false
			})
	}

	init()

	const clear = () => {
		list.value = []
		page = 1
		finish.value = false
	}

	const changeTab = (v) => {
		clear()
		tab.value = v
		init()
	}


	const searchKeywords = (e) => {
		if (e.keyCode === 13) {
			search.value.blur()
			clear()
			init()
		}
	}


	const handleClick = () => {
		clear()
		init()
	}


	const timechange = (e) => {
		ruleForm.value.startTime = e[0] ?? ''
		ruleForm.value.endTime = e[1] ?? ''
	}

	const backTask = () => {
		router.go(-1)
	}

</script>

<style lang="less" scoped>
@import url("../employment/style/list.less");

.ml_10 {
    margin-left: 10px;
}

.title {
    margin-bottom: 20px;
}

.color_red {
    color: #ff4e4e;
}

/deep/.subtitle {
    .el-input__inner {
        width: 340px;
        height: 40px;
        // border: none;
        // padding-right: 40px;
    }

	.el-range-separator {
		line-height: 30px;
	}

    .el-input__suffix {
        right: 15px;
    }

    .el-icon-search, .el-range-editor--small .el-range__icon {
        font-size: 20px;
    }
	.el-range-editor--small .el-range__icon {
		line-height: 32px;
	}
}

.el-icon-search {
    line-height: 40px;
}

.button {
    width: 130px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 25px;

    &.active {
        border-color: #1890ff;
        color: #1890ff;
    }
}

.bottom {
    position: relative;
}

.edit {
    background-color: #1890ff;
    color: #ffffff;
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    width: 66px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 30px;
}

.disable {
    background-color: #ededed;
    color: #999;
}

.title {
    padding: 19px 38px 20px 30px;
    border-radius: 2px;

    .post-icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}

.loading {
	padding-top: 30px;
}
.userinfo_tips {
	position: relative;
	.line:not(:last-child) {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			width: 1px;
			height: 50px;
			background: #ededed;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.mb_8 {
	margin-bottom: 8px;
}
.flex_end {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.color_red {
	color: #ff4e4e;
}
.title {
	padding: 25px 30px 20px 30px;
	border-radius: 2px;
}
.bottom {
	padding: 20px 30px;
	.label {
		width: 100px;
		display: inline-block;
	}
}
.list {
	.item {
		padding: 10px 40px 10px 30px;
		height: 130px;
		.left {
			.label {
				min-width: 95px;
			}
		}
		.right {
			.ritem {
				width: 160px;
			}
		}
        .tag {
            width: 80px;
            height: 20px;
            background: #FBFBFB;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            color: #B2B2B2;
            &.in {
                border-color: #F3DDAA;
                background: #FFF9F1;
                color: #E6B365;
            }
        }
        .btn {
            width: 128px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            border-radius: 15px;
            color: #999999;
            margin: 0 auto;
            &.in {
                color: #1890FF;
                border-color: #1890FF;
            }
        }
	}
}

</style>

